import { useEffect, useState } from "react";
import successImg from "./assets/badge-success.png";
import { useSearchParams } from "react-router-dom";

export default function Page() {
  const [amount, setAmount] = useState(0);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    setAmount(searchParams.get("amount"));
  }, []);

  return (
    <div className="h-[100vh] w-full flex justify-center items-center flex-col space-y-5 px-5">
      <h1 className="text-3xl font-bold text-stone-700">Thank You</h1>
      <img src={successImg} alt="Success" className="w-48 h-48" />
      <p className="text-center font-medium">
        Your ticket purchase of £{amount > 0 ? (amount / 100).toFixed(2) : 0} was successful
      </p>
    </div>
  );
}

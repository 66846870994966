import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import TicketPayment from "./TicketPayment";

export default function DonationPage() {
  const [searchParams] = useSearchParams();

  const [jwtToken, setJwtToken] = useState(null);
  const [amount, setAmount] = useState(0);
  const [event, setEvent] = useState(null);
  const [paymentIntentId, setPaymentIntentId] = useState(null);

  useEffect(() => {
    setAmount(searchParams.get("amount"));
    setJwtToken(searchParams.get("jwtToken"));
    setEvent(searchParams.get("event"));
    setPaymentIntentId(searchParams.get("paymentIntentId"));
  }, []);

  if (!jwtToken || amount == 0 || !event || !paymentIntentId) {
    return <></>;
  }

  return (
        <TicketPayment
          payload={{
            event,
            jwtToken,
            amount: amount,
            paymentIntentId
          }}
        />
    
  );
}
